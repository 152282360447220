import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Edit } from 'react-feather';
import getImage from '../../utils/getImage';

import { sendAlert } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { showProjectRequest, updateProjectRequest } from '../../requests/projects';
import basicProject from './Show/basicProject';
import ProductShow from './Show/ProductShow';
import ProjectClient from './ProjectClient';
import ProjectImages from './ProjectImages';
import ProjectInvoicesIndex from './ProjectInvoice/ProjectInvoicesIndex';
import ProjectLinkButtons from './ProjectLinkButtons';
import ProjectNavbarBudget from './ProjectNavbarBudget';
import ProjectNavbarInfo from './ProjectNavbarInfo';
import ProjectPaymentsShow from '../ProjectPayment/ProjectPaymentsShow';
import ProjectProgressBars from './ProjectProgressBars';
import ProjectShowForm from './Form/ProjectShowForm';
import ProjectVehicle from './ProjectVehicle';
import ProjectWorkShow from './Show/ProjectWorkShow';
import ProjectComments from './ProjectComments';
import ProjectClient2 from './ProjectClient2';
import ProjectTitle from "./ProjectTitle";
import {Can} from "../../config/abilityContext";


const ProjectShow = ({ match, projectVersion }) => {
  const { id, version } = match.params;
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [project, setProject] = useState(basicProject);
  const [versionedProject, setVersionedProject] = useState(basicProject);
  const [receptionImages, setReceptionImages] = useState([]);
  const [mechanicImages, setMechanicImages] = useState([]);
  const [preinspectionImages, setPreinspectionImages] = useState([]);
  const {
    deskSell: isDeskSale,
    token,
    id: projectCode,
    isQuote,
    orderType,
    sinisterNumber,
    ocNumber,
    deliveryDate,
    orderNumber
  } = project;
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const versionImages = versionedProject?.projectReviewsAttributes
    .map(review => [...review.imagesInfo.map(imgInfo => imgInfo.id)])
    .flat();

  const setImages = () => {
    if (projectVersion) return;
    const getImages = (attributes = []) => {
      return attributes
        .map(projectReview => {
          return projectReview.imagesInfo.reduce((result, image) => {
            if (versionImages.includes(image.id)) {
              result.push({
                original: image.fileUrl,
                thumbnail: getImage(image.key, 200, 200, 'inside')
              });
            }
            return result;
          }, []);
        })
        .flat();
    };
    const receptionImgs = getImages(
      project.projectReviewsAttributes.filter(projectReview => projectReview.reviewType === 'reception')
    );
    const mechanicImgs = getImages(
      project.projectReviewsAttributes.filter(projectReview => projectReview.reviewType === 'mechanic')
    );
    const preInspactionImgs = getImages(
      project.projectReviewsAttributes.filter(projectReview => projectReview.reviewType === 'pre_inspection')
    );
    setReceptionImages(receptionImgs);
    setMechanicImages(mechanicImgs);
    setPreinspectionImages(preInspactionImgs);
  };

  const handleSuccessFetchProject = response => {
    const responseProject = camelCaseEmptyStringRecursive(response.data);
    if (version) {
      const isProjectVersion = Object.keys(projectVersion.object).length > 0;
      if (isProjectVersion) {
        setVersionedProject(projectVersion.object.object);
        setProject(projectVersion.object.object);
        const {
          receptionImages: receptionImgs,
          mechanicImages: mechanigImgs,
          preinspectionImages: preInspactionImgs
        } = projectVersion;
        setReceptionImages(receptionImgs);
        setMechanicImages(mechanigImgs);
        preinspectionImages(preInspactionImgs);
      }
    } else {
      setVersionedProject(responseProject);
      setProject(responseProject);
    }
  };

  const fetchProject = () => {
    showProjectRequest(id, {
      dispatch,
      params: { screen: 'ProjectShow' },
      successCallback: handleSuccessFetchProject,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProject, [moreData, projectVersion]);
  useEffect(setImages, [project]);

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo actualizada con éxito' }));
    setMoreData(!moreData);
    setModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleUpdateRequest = values => {
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const editProjectShow = from => {
    const title = from === 'ProjectNotes' ? 'Editar Notas' :
      (from === 'EditDiscount' ? 'Editar Descuento General' : 'Editar Orden de Trabajo');
    const body = (
      <ProjectShowForm
        from={from}
        isDeskSale={isDeskSale}
        project={project}
        action="edit"
        submitVariant="success"
        formRequest={handleUpdateRequest}
      />
    );
    setModalTitle(title);
    setModalBody(body);
    setModalShow(true);
  };

  const images = (imagesInfo = []) => {
    if (imagesInfo.length) {
      return imagesInfo.map(image => ({
        original: image.fileUrl,
        thumbnail: getImage(image.key, 100, 100, 'inside')
      }));
    }
    return [];
  };

  return (
    <Can I="manage" a="Project">
      <Container>
          <ProjectTitle isQuote={isQuote} orderType={orderType} version={version} projectVersion={projectVersion}
                        id={id}/>

          <ProjectLinkButtons showActive id={id} isDeskSale={isDeskSale} version={version} token={token} clientNumber={project.client.cellphone}/>
          <ProjectNavbarInfo
              project={project}
              version={version}
              editProjectShow={editProjectShow}
              setMoreData={setMoreData}
              isQuote={isQuote}
              handleUpdateRequest={handleUpdateRequest}
          />
          <Row className="mb-3">
            <Col className="project-section mr-2 pt-3">
              <ProjectClient
                  companyRequiredFieldAttributes={project.company.companyRequiredFieldAttributes}
                  id={id}
                  project={project}
                  setMoreData={setMoreData}
                  version={version}
              />
              {project.secondClient && <ProjectClient2 client={project.secondClient}/>}
            </Col>
            {project.vehicle && (
                <Col className="project-section ml-2 pt-3">
                  <ProjectVehicle
                      companyRequiredFieldAttributes={project.company.companyRequiredFieldAttributes}
                      project={project}
                      version={version}
                      setMoreData={setMoreData}
                  />
                </Col>
            )}
          </Row>
          <Row className="mb-3">
            <Col className="project-section mr-2 pt-3">
              {orderType === 'reception_secure' ? (
                  <Row>
                    <strong className="ml-3">Orden con Recepción y Seguro</strong>
                    <Col md={10} className="mb-2 mt-3">
                      <strong className="mr-1">Número de siniestro:</strong>
                      {sinisterNumber}
                    </Col>
                    {!version && (
                        <Col md={2}>
                          <Button
                              variant="link"
                              className="btn-sm text-dark float-right"
                              onClick={() => editProjectShow('ProjectReceptionSecure')}
                          >
                            <Edit width="15px" height="15px"/>
                          </Button>
                        </Col>
                    )}
                    {ocNumber && (
                        <Col md={12} className="mb-2">
                          <strong className="mr-1">Número OC:</strong>
                          {ocNumber}
                        </Col>
                    )}
                    {deliveryDate && (
                        <Col md={12} className="mb-2">
                          <strong className="mr-1">Fecha de entrega:</strong>
                          {deliveryDate}
                        </Col>
                    )}
                    {orderNumber && (
                        <Col md={12} className="mb-2">
                          <strong className="mr-1">Número de pedido:</strong>
                          {orderNumber}
                        </Col>
                    )}
                    <Col md={12} className="mb-4">
                      <strong>Motivo / Notas</strong>
                      <pre>{project.notes}</pre>
                    </Col>
                  </Row>
              ) : (
                  <Row>
                    <Col md={10}>
                      <strong>Motivo / Notas</strong>
                    </Col>
                    {!version && (
                        <Col md={2}>
                          <Button
                              variant="link"
                              className="btn-sm text-dark float-right"
                              onClick={() => editProjectShow('ProjectNotes')}
                              disabled={project.id === ''}
                          >
                            <Edit width="15px" height="15px"/>
                          </Button>
                        </Col>
                    )}
                    <Col md={12} className="pt-2">
                      <pre>{project.notes}</pre>
                    </Col>
                  </Row>
              )}
              {projectCode && <ProjectComments projectCode={projectCode}/>}
            </Col>
            {!isDeskSale && !isQuote && (
                <Col className="project-section ml-2 pt-3">
                  <Row>
                    <Col md={12}>
                      <strong>Imágenes</strong>
                    </Col>

                    {orderType === 'reception_secure' && (
                        <Col className="gallery-small-box">
                          <ProjectImages
                              images={projectVersion ? images(preinspectionImages) : preinspectionImages}
                              title="Checklist de Pre Inspección"
                          />
                        </Col>
                    )}
                    <Col className="gallery-small-box">
                      <ProjectImages
                          images={projectVersion ? images(receptionImages) : receptionImages}
                          title="Checklist recepción"
                      />
                    </Col>
                    <Col className="gallery-small-box">
                      <ProjectImages
                          images={projectVersion ? images(mechanicImages) : mechanicImages}
                          title="Checklist mecánico"
                      />
                    </Col>
                  </Row>
                </Col>
            )}
          </Row>
          <Row className="mb-3">
            {!isDeskSale && !isQuote && (
                <Col md={4} className="project-section mr-2 pt-3">
                  <ProjectProgressBars
                      project={project}
                      id={id}
                      version={version} // this handle the dinamyc of modals in work orders progress bar
                      versionNum={projectVersion ? projectVersion.versionNumber : ''}
                      setMoreData={setMoreData}
                  />
                </Col>
            )}
            <Col className={`project-section pt-3 ${isDeskSale || isQuote ? '' : 'ml-2'}`}>
              <ProjectWorkShow project={project}/>
            </Col>
          </Row>
          <ProjectNavbarBudget
              project={project}
              version={version}
              versionNum={projectVersion ? projectVersion.versionNumber : ''}
              onClick={() => editProjectShow('ProjectNavbarBudget')}
              handleChangeDiscount={() => editProjectShow('EditDiscount')}
              setMoreData={setMoreData}
              isDeskSale={isDeskSale}
              handleUpdateRequest={handleUpdateRequest}
          />
          {!isQuote && (
              <>
                <Row className="mb-3">
                  <Col md={5} className="project-section mr-2 pt-3">
                    <ProjectPaymentsShow project={project} version={version} setMoreData={setMoreData}/>
                  </Col>
                  <Col className="project-section ml-2 pt-3">
                    <ProjectInvoicesIndex project={project} version={version} setMoreData={setMoreData}/>
                  </Col>
                </Row>
              </>
          )}
          <Row className="mb-3">
            <Col className="project-section pt-3">
              <ProductShow project={project} setMoreData={setMoreData}/>
            </Col>
          </Row>

          <SimpleCenteredModal
              size="sm"
              title={modalTitle}
              body={modalBody}
              show={modalShow}
              onHide={() => setModalShow(false)}
          />
        </Container>
    </Can>
  );
};

export default ProjectShow;
