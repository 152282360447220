import React from 'react';
import memoize from 'memoize-one';
import { Form } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Link } from 'react-router-dom';
import { Plus } from 'react-feather';
import ReactTooltip from "react-tooltip";
import { ButtonTooltip } from '../../components';

const columns = memoize(formRequest => {
  const handleCheckBox = (item, attribute) => {
    const { id, hasAutomaticPurchase, hasOnePurchase, [`${attribute}`]: vAttribute } = camelCaseRecursive(item);
    const isDisabled = attribute === 'requestPurchase' ? hasOnePurchase : hasOnePurchase && hasAutomaticPurchase;
    return (
      <Form.Check
        custom
        type="checkbox"
        label=""
        checked={vAttribute}
        onChange={e =>
          formRequest({
            id,
            [`${attribute}`]: e.target.checked,
            ...(attribute === 'purchaseMade'
              ? {
                  purchase_date: new Date().toLocaleDateString('es-ES')
                }
              : {})
          })
        }
        className="checkbox-associate-purchase"
        id={`${attribute}_${id}`}
        disabled={isDisabled}
      />
    );
  };

  return [
    {
      name: 'OT',
      selector: 'project_id',
      sortable: false,
      grow: '1'
    },
    {
      name: 'ESTADO OT',
      selector: 'project_ot_state',
      sortable: false,
      grow: '2'
    },
    {
      name: 'TRABAJO',
      selector: 'project_work_name',
      sortable: false,
      grow: '3'
    },
    {
      name: 'CATEGORÍA',
      selector: 'category_name',
      sortable: false,
      grow: '2'
    },
    {
      name: 'VEHÍCULO',
      selector: 'vehicle_info',
      sortable: false,
      grow: '4',
      cell: ({ vehicle }) => vehicle?.vehicle_description || '-'
    },
    {
      name: 'CÓDIGO',
      selector: 'vehicle_plate',
      sortable: false,
      grow: '2',
      cell: ({ vehicle }) => vehicle?.plate || '-'
    },
    {
      name: 'AÑO VEHICULO',
      selector: 'vehicle_year',
      sortable: false,
      grow: '2',
      cell: ({ vehicle }) => vehicle?.year || '-'
    },
    {
      name: 'ÍTEM',
      selector: 'product_name',
      cell: item => item.product_name_show || '-',
      sortable: false,
      grow: '3'
    },
    {
      name: 'CANTIDAD',
      selector: 'quantity',
      sortable: false,
      grow: '1'
    },
    {
      name: 'COSTO UNITARIO',
      selector: 'cost',
      sortable: false,
      grow: '1',
      cell: ({ parsed_cost: parsedCost }) => parsedCost
    },
    {
      name: 'PRECIO UNITARIO',
      selector: 'unit_price',
      sortable: false,
      grow: '1',
      cell: ({ parsed_unit_price: parsedUnitPrice }) => parsedUnitPrice
    },
    {
      name: 'FECHA DE COMPRA',
      selector: 'purchase_date',
      cell: item => (item.purchase_date ? item.purchase_date.split(' ')[0] : '-'),
      sortable: false,
      grow: '2'
    },
    {
      name: 'NOTAS',
      selector: 'notes',
      sortable: false,
      grow: '2',
      cell: item => (
          <div data-tip data-for={`noteTip-${item.id}`} className="d-inline-block text-truncate">
            {item.notes}
            <ReactTooltip id={`noteTip-${item.id}`} place="top" effect="solid">
              {item.notes}
            </ReactTooltip>
          </div>
      ),
    },
    {
      name: 'SOLICITAR COMPRA',
      selector: 'request_purchase',
      cell: item => handleCheckBox(item, 'requestPurchase'),
      sortable: false,
      button: true,
      minWidth: '100px'
    },
    {
      name: 'COMPRA REALIZADA',
      selector: 'purchase_made',
      cell: item => handleCheckBox(item, 'purchaseMade'),
      sortable: false,
      button: true,
      minWidth: '100px'
    },
    {
      cell: item => (
        <>
          <ButtonTooltip
            to={() => {
              return `movements/new?project_code=${item.project_id}`;
            }}
            variant="outline-info"
            className="btn-circle table-actions-btn"
            size="sm"
            text="Realizar Movimiento"
            as={Link}
          >
            <Plus width="15px" height="15px" />
          </ButtonTooltip>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1'
    }
  ];
});

export default columns;
