import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import {Col, Form, Row} from "react-bootstrap";
import {defaultStates} from '../Project/projectFiltersData';
import {sendAlert} from '../../actions/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import {FormikSelect, InputRemoteSelect} from '../../components';
import {indexProjectDetailsRequest, updateProjectDetailRequest} from '../../requests/projectDetails';
import {debounceIndexProductCategoriesRequest, indexProductCategoriesRequest} from '../../requests/productCategories';
import {indexUsersRequest} from "../../requests/user";
import InputDate from "../../components/Utils/Input/InputDate";
import useSavedFilter from "../../hooks/useSavedFilter";

const ProjectDetailDataTable = ({columns, defaultParams, setQueries, filterId}) => {
  const {setFilter, getFilter} = useSavedFilter(filterId);
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(getFilter('category_id'));
  const [purchaseMade, setPurchaseMade] = useState(getFilter('purchase_made'));
  const [requestPurchase, setRequestPurchase] = useState(getFilter('request_purchase'));
  const [deliveredDateFrom, setDeliveredDateFrom] = useState(getFilter('delivered_date_from'));
  const [deliveredDateTo, setDeliveredDateTo] = useState(getFilter('delivered_date_to'));
  const [stateId, setStateId] = useState(getFilter('state_id'));
  const [metadata, setMetadata] = useState({});
  const [doneById, setDoneById] = useState(getFilter('done_by_id'));
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const requestPurchaseOptions = [
    {label: 'Compra Solicitada', value: true},
    {label: 'Compra No Solicitada', value: false}
  ];
  const purchaseMadeOptions = [
    {label: 'Compra Realizada', value: true},
    {label: 'Compra No Realizada', value: false}
  ];

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
    setOnRequest(false);
  };

  const handleSuccessIndexRequest = response => {
    const {data, metadata: metaData} = response.data;
    setProjectDetails(data);
    setAmount(metadata.amount);
    setMetadata(metaData);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    indexProjectDetailsRequest({
      dispatch,
      params: {
        ...params,
        ...defaultParams,
        state_id: stateId,
        category_id: categoryId,
        purchase_made: purchaseMade,
        request_purchase: requestPurchase,
        delivered_date_from: deliveredDateFrom,
        delivered_date_to: deliveredDateTo,
        done_by_id: doneById
      },
      successCallback: handleSuccessIndexRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessUpdateRequest = () => {
    dispatch(sendAlert({kind: 'success', message: 'Detalle actualizado con éxito'}));
    setMoreData(!moreData);
  };

  const handleUpdateRequest = params => {
    updateProjectDetailRequest(params.id, {
      dispatch,
      params: {project_detail: snakeCaseKeys(params)},
      formData: true,
      successCallback: handleSuccessUpdateRequest,
      failureCallback: handleFailureRequest
    });
  };

  const resultFetchData = response => {
    const result = response.data.data;
    return result;
  };

  const fetchCategories = (inputValue, callback) => {
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        query: inputValue,
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const fetchInitialCategories = useCallback(
    params => {
      indexProductCategoriesRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          sort_direction: 'asc',
          filter_parents: true,
          for_selector: true,
          display_length: 50
        },
        successCallback: response => {
          setCategories(resultFetchData(response));
        }
      });
    },
    [stableDispatch]
  );

  const handleSetUsers = response => {
    const responseUsers = response.data.data;
    setUsers(responseUsers);
  };

  const fetchUsers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'email',
        display_length: 100
      },
      successCallback: handleSetUsers
    });
  };

  useEffect(fetchInitialCategories, [fetchInitialCategories, moreData]);
  useEffect(fetchUsers, []);

  return (
    <>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Costo Total:</div>
          <div>{metadata.sum_total_cost}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Precio Total:</div>
          <div>{metadata.sum_price}</div>
        </Col>
      </Row>
      <ComponentDataTable
        filterId={filterId}
        withDate
        onRequest={onRequest}
        columns={columns(handleUpdateRequest)}
        data={projectDetails}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          if (setQueries)
            setQueries({
              ...response,
              state_id: stateId,
              category_id: categoryId,
              purchase_made: purchaseMade,
              request_purchase: requestPurchase,
              delivered_date_from: deliveredDateFrom,
              delivered_date_to: deliveredDateTo,
              done_by_id: doneById
            });
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          <FormikSelect
            isClearable
            options={defaultStates}
            placeholder="Filtrar Estado de OT"
            defaultValue={defaultStates.find(state => state.value === stateId)}
            onChange={data => {
              setFilter('state_id', data?.value);
              setStateId(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
        secondCustomMediumSearch={
          <InputRemoteSelect
            isClearable
            placeholder="Filtrar Categoría"
            defaultOptions={categories}
            value={categories.find(category => category.value === categoryId)}
            onChange={data => {
              setFilter('category_id', data?.value);
              setCategoryId(data ? data.value : '');
              setMoreData(!moreData);
            }}
            request={fetchCategories}
          />
        }
        thirdCustomMediumSearch={
          <FormikSelect
            isClearable
            placeholder="Filtrar por Compras Solicitadas"
            options={requestPurchaseOptions}
            defaultValue={requestPurchaseOptions.find(option => option.value === requestPurchase)}
            onChange={data => {
              setFilter('request_purchase', data?.value);
              setRequestPurchase(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
        fourthCustomMediumSearch={
          <FormikSelect
            isClearable
            placeholder="Filtrar por Compras Realizadas"
            options={purchaseMadeOptions}
            defaultValue={purchaseMadeOptions.find(option => option.value === purchaseMade)}
            onChange={data => {
              setFilter('purchase_made', data?.value);
              setPurchaseMade(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
        filters={
          <>
            <Col xs={6} md={2}>
              <Form.Group>
                <InputDate
                  placeholderText="Entregado desde"
                  selected={deliveredDateFrom}
                  onChange={date => {
                    setFilter('delivered_date_from', date);
                    setDeliveredDateFrom(date);
                    setMoreData(!moreData);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6} md={2}>
              <Form.Group>
                <InputDate
                  placeholderText="Entregado hasta"
                  selected={deliveredDateTo}
                  onChange={date => {
                    setFilter('delivered_date_to', date);
                    setDeliveredDateTo(date);
                    setMoreData(!moreData);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6} md={2}>
              <FormikSelect
                isClearable
                options={users}
                placeholder="Asignado"
                defaultValue={users.find(user => user.value === doneById)}
                onChange={data => {
                  setFilter('done_by_id', data?.value);
                  setDoneById(data ? data.value : '');
                  setMoreData(!moreData);
                }}
              />
            </Col>
          </>
        }
      />
    </>
  );
};

export default ProjectDetailDataTable;
