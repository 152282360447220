import React from 'react';
import ContactsShow from '../Contact/ContactsShow';

const clientInfo = props => {
  const {
    client: {
      active,
      businessActivity,
      cellphone,
      contactsAttributes,
      email,
      fullAddress,
      fullName,
      legalName,
      nationalIdentification,
      notes,
      phone,
      seller,
      isCompany
    },
    countryFactory
  } = props;

  return (
    <div className="show-info-container">
      <div className="show-info-small-container">
        <div className="show-info-column">
          <p>
            <strong>{countryFactory.getLabelForNationalIdentification()}:</strong> {nationalIdentification}
          </p>
          {isCompany ? (
            <p>
              <strong>Nombre Empresa:</strong> {fullName}
            </p>
          ) : (
            <p>
              <strong>Nombre Completo:</strong> {fullName}
            </p>
          )}
          <p className="address-line">
            <strong>Dirección:</strong> {fullAddress}
          </p>
        </div>
        <div className="show-info-column">
          {isCompany && (
            <>
              <p>
                <strong>Razón Social:</strong> {legalName}
              </p>
              <p>
                <strong>Actividad Comercial:</strong> {businessActivity}
              </p>
            </>
          )}
        </div>
      </div>
      <hr />
      <div className="show-info-small-container">
        <div className="show-info-column">
          <p>
            <strong>Email:</strong> {email}
          </p>
          <p>
            <strong>Teléfono:</strong> {phone}
          </p>
          <p>
            <strong>Celular:</strong> {cellphone}
          </p>
        </div>
        <div className="show-info-column">
          <p>
            <strong>Notas:</strong> {notes}
          </p>
          <p>
            <strong>Activo:</strong> {active ? 'Sí' : 'No'}
          </p>
          <p>
            <strong>Vendedor:</strong> {seller?.label}
          </p>
        </div>
      </div>
      <ContactsShow contactsAttributes={contactsAttributes} />
    </div>
  );
};

export default clientInfo;
