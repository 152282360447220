import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { textUpperCase } from '../../services/utils';
import { indexUsersRequest } from '../../requests/user';
import { showCompanyRequest } from '../../requests/companies';
import {
  BasicTextArea,
  FormikCheckBox,
  FormikInput,
  FormikNumberFormat,
  FormikSelect,
  RegionCommuneSelect
} from '../../components';
import ContactAdd from '../Contact/ContactAdd';
import useCountryFactory from "../../hooks/useCountryFactory";

const ClientFormInputs = ({
  clientTab,
  client,
  companyRequiredFieldAttributes,
  errors,
  setFieldValue,
  touched,
  values,
  editFromProject
}) => {
  const countryFactory = useCountryFactory();
  const sellerId = client?.seller?.value;
  const insuranceCompanyId = client?.insuranceCompany?.value;
  const contactsAttributes = values?.client?.contactsAttributes;
  const [sellers, setSellers] = useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const dispatch = useDispatch();

  const fetchSellers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'email',
        display_length: 100
      },
      successCallback: response => setSellers(response.data.data)
    });
  };

  useEffect(fetchSellers, []);

  const fetchInsuranceCompanies = () => {
    showCompanyRequest({
      dispatch,
      successCallback: response => setInsuranceCompanies(response.data.insurance_companies)
    });
  };

  useEffect(fetchInsuranceCompanies, []);

  const handleTextFormatting = e => {
    const formattedValue = textUpperCase(e.target.value);
    setFieldValue(e.target.name, formattedValue);
  };

  const [isCompany, setIsCompany] = useState(false);
  useEffect(() => setIsCompany(client.isCompany), [client]);

  const handleClientTypeCheckbox = e => {
    if (e.target.checked) {
      setIsCompany(true);
      setFieldValue('client[firstLastName]', '');
      setFieldValue('client[secondLastName]', '');
    } else {
      setIsCompany(false);
      setFieldValue('client[legalName]', '');
      setFieldValue('client[businessActivity]', '');
    }
    setFieldValue(e.target.name, isCompany);
  };

  return (
    <>
      <Col md={12}>
        <Field name="client[isForeign]">
          {({ field }) => (
            <FormikCheckBox
              {...field}
              field={field}
              label="¿Es extranjero?"
              custom
            />
          )}
        </Field>
      </Col>

      <Col md={12}>
        <Field name="client[nationalIdentification]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr={companyRequiredFieldAttributes.clientNationalIdentification !== false}
              label={countryFactory.getLabelForNationalIdentification()}
              onChange={e => countryFactory.handleNationalIdentificationFormat(e, setFieldValue, values.client.isForeign)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="client[name]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Nombre"
              onChange={e => handleTextFormatting(e)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>

      <Col md={6}>
        <Field name="client[isCompany]">
          {({ field }) => (
            <FormikCheckBox
              {...field}
              field={field}
              label="¿Eres Empresa?"
              onClick={e => handleClientTypeCheckbox(e)}
              custom
            />
          )}
        </Field>
      </Col>
      <Col md={6}>
        <Field name="client[insuranceCompanyId]">
          {({ field }) => (
            <FormikSelect
              {...field}
              label="Si es una Compañía de Seguros, selecciónala"
              placeholder="Seleccionar Compañía"
              options={insuranceCompanies}
              defaultValue={insuranceCompanyId}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>

      {!isCompany && (
        <>
          <Col md={6}>
            <Field name="client[firstLastName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Apellido Paterno"
                  onChange={e => handleTextFormatting(e)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="client[secondLastName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Apellido Materno"
                  onChange={e => handleTextFormatting(e)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
      {isCompany && (
        <>
          <Col md={12}>
            <Field name="client[legalName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Razón Social"
                  onChange={e => handleTextFormatting(e)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="client[businessActivity]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Giro"
                  onChange={e => handleTextFormatting(e)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}

      <Col md={8}>
        <Field name="client[address]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr={companyRequiredFieldAttributes.clientAddress}
              label="Dirección"
              onChange={e => handleTextFormatting(e)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={4}>
        <Field name="client[addressNumber]">
          {({ field }) => (
            <FormikInput
              {...field}
              label="Detalle"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <RegionCommuneSelect
        regionAbbr={companyRequiredFieldAttributes.regionAndCommune}
        communeAbbr={companyRequiredFieldAttributes.regionAndCommune}
        modelKey="client"
        dispatch={dispatch}
        currentModel={client}
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        countryFactory={countryFactory}
      />
      {/* {!clientTab && (
        <> */}
          <Col md={12}>
            <Field name="client[sellerId]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Vendedor"
                  placeholder="Seleccionar Vendedor"
                  options={sellers}
                  defaultValue={sellerId}
                  onChange={data => setFieldValue(field.name, data ? data.value : '')}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        {/* </>
      )} */}
      <Col md={12}>
        <Field name="client[email]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr={companyRequiredFieldAttributes.clientEmail}
              label="Email"
              onChange={e => handleTextFormatting(e)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12}>
        <Field name="client[cellphone]">
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr={companyRequiredFieldAttributes.clientCellphone}
              label="Celular"
              format={countryFactory.formatPhone}
              onValueChange={number => setFieldValue(field.name, number.formattedValue)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      {!clientTab && (
        <>
          <Col md={12}>
            <Field name="client[phone]">
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  label="Teléfono"
                  format={countryFactory.formatPhone}
                  onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="client[notes]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Notas"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
      {!editFromProject && (
        <>
          <Col md={12}>
            <Field name="client[active]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" custom />}
            </Field>
          </Col>
          <Col md={12}>
            <h4 className="text-uppercase mt-3 mb-4">Contactos</h4>
            <ContactAdd
              contactsAttributes={contactsAttributes}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              parent="client"
            />
          </Col>
        </>
      )}
    </>
  );
};

const ClientForm = props => {
  const {
    action,
    clientTab,
    client,
    companyRequiredFieldAttributes,
    errors,
    onHide,
    setFieldValue,
    submitVariant,
    touched,
    values,
    formSize,
    formClassName,
    modelKey,
    editFromProject
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      {clientTab ? (
        <Row>
          <Col md={formSize} className={formClassName}>
            <Row>
              <Col xs={7} className="mt-3 mb-2">
                <h3 className={`text-uppercase ${modelKey === 'calendarEvent' ? 'hidden-title' : ''}`}>
                  Crear Cliente
                </h3>
              </Col>
              <Col xs={5} className={`mt-3 ${modelKey === 'calendarEvent' ? 'modal-save-new-form-btn' : ''}`}>
                <Row>
                  <Col xs={6} className="ml-n2">
                    <Button variant="link" onClick={onHide}>
                      Cancelar
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="submit" variant="link">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Col>
              <ClientFormInputs
                client={client}
                clientTab={clientTab}
                companyRequiredFieldAttributes={companyRequiredFieldAttributes}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                values={values}
                editFromProject={editFromProject}
              />
              <Col xs={12} className={`mb-3 d-flex justify-content-between`}>
                <Button onClick={onHide} variant="light-darker">
                  Cancelar
                </Button>
                <Button type="submit" variant={"success"}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <ClientFormInputs
              client={client}
              clientTab={clientTab}
              companyRequiredFieldAttributes={companyRequiredFieldAttributes}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              editFromProject={editFromProject}
            />
          </Row>
          <Row className="d-flex justify-content-end mb-3">
            <Col md={2}>
              <Button type="submit" variant={submitVariant} block onClick={onHide}>
                {btnMessage}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

const setInitialValues = props => {
  const { client, clientTab } = props;
  return { client, clientTab };
};

// Set of the dynamic validation attributes on Yup corresponding to companyRequiredFieldAttributes

const address = companyRequiredFieldAttributes => {
  if (companyRequiredFieldAttributes.clientAddress) {
    return Yup.string().required('Debes ingresar una dirección');
  }
  return Yup.string().nullable();
};

const cellphone = (companyRequiredFieldAttributes, country) => {
  if (companyRequiredFieldAttributes.clientCellphone) {
    return Yup.string()
      .required('Debes ingresar un número de celular')
      .min(13, 'Debe tener al menos 9 caracteres')
      .phone('El formato no es válido', country);
  }
  return Yup.string()
    .nullable()
    .min(13, 'Debe tener al menos 9 caracteres')
    .phone('El formato no es válido', country);
};

const commune = companyRequiredFieldAttributes => {
  if (companyRequiredFieldAttributes.regionAndCommune) {
    return Yup.string().required('Debes seleccionar una comuna');
  }
  return Yup.string().nullable();
};
const email = companyRequiredFieldAttributes => {
  if (companyRequiredFieldAttributes.clientEmail) {
    return Yup.string()
      .required('Debes ingresar un email')
      .email('Debes ingresar un email válido');
  }
  return Yup.string().nullable();
};

const region = companyRequiredFieldAttributes => {
  if (companyRequiredFieldAttributes.regionAndCommune) {
    return Yup.string().required('Debes seleccionar una región');
  }
  return Yup.string().nullable();
};

const nationalIdentification = (companyRequiredFieldAttributes, country) => {
  if (companyRequiredFieldAttributes.clientNationalIdentification !== false) {
    return Yup.string().when('isForeign', {
      is: true,
      then: Yup.string().required('Debes ingresar un RUT'),
      otherwise: Yup.string().required('Debes ingresar un RUT').rut('El RUT es inválido', country)
    })
  }
  return Yup.string().when('isForeign', {
    is: true,
    then: Yup.string().nullable(),
    otherwise: Yup.string().nullable().rut('El RUT es inválido', country)
  })
};

const fullClient = (companyRequiredFieldAttributes, country) => {
  return Yup.object().when('clientTab', {
    is: true,
    then: Yup.object().shape({
      address: address(companyRequiredFieldAttributes),
      businessActivity: Yup.string().nullable(),
      cellphone: cellphone(companyRequiredFieldAttributes, country),
      communeId: commune(companyRequiredFieldAttributes),
      email: email(companyRequiredFieldAttributes),
      firstLastName: Yup.string().when('isCompany', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required('Debes ingresar un apellido paterno')
      }),
      legalName: Yup.string().when('isCompany', {
        is: true,
        then: Yup.string().required('Debes ingresar una razón social'),
        otherwise: Yup.string().nullable()
      }),
      name: Yup.string().required('Debes ingresar un nombre'),
      nationalIdentification: nationalIdentification(companyRequiredFieldAttributes, country),
      regionId: region(companyRequiredFieldAttributes)
    }),
    otherwise: Yup.object().shape({
      active: Yup.boolean(),
      address: address(companyRequiredFieldAttributes),
      businessActivity: Yup.string().nullable(),
      insuranceCompanyId: Yup.string().nullable(),
      cellphone: cellphone(companyRequiredFieldAttributes, country),
      communeId: commune(companyRequiredFieldAttributes),
      email: email(companyRequiredFieldAttributes),
      firstLastName: Yup.string().when('isCompany', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required('Debes ingresar un apellido paterno')
      }),
      legalName: Yup.string().when('isCompany', {
        is: true,
        then: Yup.string().required('Debes ingresar una razón social'),
        otherwise: Yup.string().nullable()
      }),
      name: Yup.string().required('Debes ingresar un nombre'),
      nationalIdentification: nationalIdentification(companyRequiredFieldAttributes, country),
      notes: Yup.string().nullable(),
      phone: Yup.string()
        .min(13, 'Debe tener al menos 9 caracteres')
        .phone('El formato no es válido', country)
        .nullable(),
      regionId: Yup.string().when('clientTab', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: region(companyRequiredFieldAttributes)
      }),
      secondLastName: Yup.string().nullable(),
      sellerId: Yup.string().nullable(),
      contactsAttributes: Yup.array().of(
        Yup.object().shape({
          cellphone: Yup.string()
            .required('Debes ingresar un número de celular')
            .min(13, 'Debe tener al menos 9 caracteres')
            .phone('El formato no es válido', country),
          charge: Yup.string().nullable(),
          email: Yup.string()
            .required('Debes ingresar un email')
            .email('Debes ingresar un email válido'),
          name: Yup.string().required('Debes ingresar un nombre'),
          phone: Yup.string()
            .min(13, 'Debe tener al menos 9 caracteres')
            .phone('El formato no es válido', country)
            .nullable()
        })
      )
    })
  });
};

const validationSchema = ({ companyRequiredFieldAttributes, country }) => {
  return Yup.object().shape({
    client: fullClient(companyRequiredFieldAttributes, country)
  });
};

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ClientForm);
