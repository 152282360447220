import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import moment from 'moment';

import ReactSelect from 'react-select';
import { sendAlert } from '../../actions/utils';
import { BasicDropdown, DefaultModal, SimpleCenteredModal } from '../../components';
import { createProjectVersionRequest, createProjectRequest } from '../../requests/projects';
import CalendarEventForm from './Form/CalendarEventForm';
import { createCalendarEventsRequest } from '../../requests/calendarEvents';
import ProjectExports from "./ProjectExports";

const orderTypesOptions = [
  { label: 'Orden con Recepción', value: 'reception' },
  { label: 'Orden con Recepción y Seguro', value: 'reception_secure' }
];

const defaultOrderTypeOption = orderTypesOptions[0];

const ProjectNavbarInfo = ({
                             currentVersion,
                             editProjectShow,
                             fromMechanicCard,
                             fromProjectEdit,
                             project,
                             publicVersion,
                             setMoreData,
                             version,
                             isQuote,
                             handleUpdateRequest
                           }) => {
  const {
    id,
    companyTaxRate,
    formatedPromiseDate,
    parsedMileage,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    preInspectionDate,
    projectDate,
    projectVersions,
    reviewDate,
    translatedOtState,
    formatedDeliveredDate,
    receptionDate,
    otState,
    isDeskSale
  } = project;

  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [actionModalConfirm, setActionModalConfirm] = useState(null);
  const [dropdownVersionItems, setDropdownVersionItems] = useState([]);
  const [orderType, setOrderType] = useState(defaultOrderTypeOption);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessCreateNewOt = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo creada correctamente' }));
    setDefaultModalShow(false);
    setMoreData(m => !m);
    setOrderType(defaultOrderTypeOption);
  };

  const handleCreateOT = () => {
    const sendParams = {
      created_from_quote: true,
      order_type: orderType.value,
      project: {
        quote_id: id
      }
    };
    createProjectRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessCreateNewOt,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Versión generada correctamente' }));
    setDefaultModalShow(false);
    setMoreData(m => !m);
  };

  const handleNewVersion = () => {
    createProjectVersionRequest(id, {
      dispatch,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  const execFunction = action => {
    const functions = {
      handleNewVersion,
      handleCreateOT
    }
    functions[action]();
  }

  const dropdownProjectVersions = () => {
    let versions = [];
    if (projectVersions.length > 0) {
      versions = projectVersions.map(pv => ({
        key: `nn-pv-${pv.versionNumber}`,
        title: `Versión ${pv.versionNumber}`,
        onClick: () => history.push(`/projects/${id}/versions/${pv.versionNumber}`)
      }))
    }
    versions = [...versions, {
      key: '1',
      title: `Versión ${projectVersions.length + 1} (actual)`,
      onClick: () => history.push(`/projects/${id}`), active: true
    }]
    setDropdownVersionItems(versions);
  };

  const handleCloseDefaultModal = () => {
    setModalTitle('');
    setModalBody('');
    setDefaultModalShow(false);
  };

  const handleClickGenerateVersion = () => {
    setActionModalConfirm('handleNewVersion');
    setModalTitle('Generar Versión');
    setModalBody('¿Estás seguro que deseas generar una nueva versión de esta orden?');
    setDefaultModalShow(true);
  };

  const handleClickCreateOtFromQuote = () => {
    setActionModalConfirm('handleCreateOT');
    setModalTitle('Crear OT a partir de presupuesto');
    setModalBody(() => (
      <div>
        <Row>
          <Col md={12}>
            <label>
              <b>Tipo de Orden</b>
            </label>
            <ReactSelect
              options={orderTypesOptions}
              placeholder="Tipo de Orden"
              defaultValue={orderType}
              name="order-type"
              onChange={option => setOrderType(option)}
              isSearchable
            />
          </Col>
        </Row>
        <p className="mt-3">¿Estás seguro que deseas generar una Orden de Trabajo a partir de éste presupuesto?</p>
      </div>
    ));
    setDefaultModalShow(true);
  };

  const handleCreateCalendarEventRequest = params => {
    const baseDate = moment(params.calendarEvent.dateSelected, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const startDate = moment(
      `${baseDate}T${moment(params.calendarEvent.startDate).format('HH:mm')}`,
      'YYYY-MM-DDTHH:mm'
    ).toDate();
    const endDate = moment(
      `${baseDate}T${moment(params.calendarEvent.endDate).format('HH:mm')}`,
      'YYYY-MM-DDTHH:mm'
    ).toDate();
    const calendar_event = {
      order_type: params.calendarEvent.orderType,
      start_date: startDate,
      end_date: endDate,
      quote_id: id,
      client_id: project.clientId,
      notes: project.notes,
      vehicle_id: project.vehicleId,
      promise_date: project.promiseDate,
      seller_id: project.sellerId,
      price_discount: project.priceDiscount,
      percentage_discount: project.percentageDiscount
    };
    createCalendarEventsRequest({
      dispatch,
      params: { calendar_event },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cita agendada con éxito' }));
        setModalShow(false);
        setMoreData(m => !m);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleClickCreateCalendarEventFromQuote = () => {
    setModalTitle('Crear cita a partir de presupuesto');
    setModalBody(() => (
      <>
        {project.quoteHasOt && (
          <div className="text-danger" style={{ fontSize: '11px' }}>
            Ya existe una cita asociada a este presupuesto
          </div>
        )}
        <CalendarEventForm
          calendarEvent={{ startDate: moment().toDate() }}
          formRequest={handleCreateCalendarEventRequest}
          submitVariant="success"
          handleClose={handleCloseDefaultModal}
        />
      </>
    ));
    setModalShow(true);
  };

  useEffect(dropdownProjectVersions, [projectVersions]);

  return (
    <Row className="mb-3 pt-3 project-section project-show-nav small d-flex align-items-center">
      <Col md={fromMechanicCard || version ? 12 : publicVersion && 7}>
        <Row className="nav-info-section text-uppercase">
          <Col md={publicVersion ? 7 : 6} className={`${!publicVersion ? 'project-info-division' : ''}`}>
            <Row>
              {project.orderType === 'reception_secure' && (
                <Col>
                  <strong>Fecha Pre inspección</strong>
                  <p>{preInspectionDate}</p>
                </Col>
              )}
              <Col>
                <strong>Fecha Ingreso</strong>
                <p>{projectDate}</p>
              </Col>
              <Col>
                <strong>Fecha de recepción</strong>
                <p>{receptionDate}</p>
              </Col>
              <Col>
                <strong>Fecha Revisión</strong>
                <p>{reviewDate}</p>
              </Col>
            </Row>
          </Col>
          <Col
            md={publicVersion ? 5 : 6}
            className={`${
              !version && !publicVersion && !fromMechanicCard && !fromProjectEdit ? 'project-info-division' : ''
            }`}
          >
            <Row>
              <Col>
                <strong>Fecha estimada de entrega</strong>
                <p>{formatedPromiseDate}</p>
              </Col>
              <Col>
                <strong>Fecha de entrega</strong>
                <p>{formatedDeliveredDate}</p>
              </Col>
              <Col className={`${publicVersion ? 'project-info-division' : ''}`}>
                <strong>Kilometraje</strong>
                <p>{parsedMileage}</p>
              </Col>
              {!publicVersion && !isQuote && (
                <Col>
                  <strong>Estado:</strong> {translatedOtState}
                  <Button
                    variant="success"
                    className="project-show-nav-btn mt-2"
                    onClick={() => editProjectShow('NavbarInfo')}
                    disabled={project.id === '' || fromProjectEdit || version || fromMechanicCard || publicVersion}
                  >
                    Cambiar estado
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      {!fromMechanicCard && !fromProjectEdit && !version && !publicVersion && (
        <Col xs={6} md={4}>
          <Row className="d-flex align-items-center">
            <ProjectExports
              id={id}
              version={!!version}
              versionNum={version}
              setMoreData={setMoreData}
              project={project}
              isDeskSale={isDeskSale}
              handleUpdateRequest={handleUpdateRequest}
            />
            <Col>
              {isQuote && (
                <>
                  <Button
                    block
                    disabled={version}
                    variant="light-darker"
                    className="project-show-nav-btn"
                    onClick={handleClickCreateOtFromQuote}
                  >
                    Generar OT
                  </Button>
                  <Button
                    block
                    disabled={version}
                    variant="light-darker"
                    className="project-show-nav-btn"
                    onClick={handleClickCreateCalendarEventFromQuote}
                  >
                    Crear Cita
                  </Button>
                </>
              )}
              <Button
                block
                disabled={version}
                variant="light-darker"
                className="project-show-nav-btn"
                onClick={handleClickGenerateVersion}
              >
                Generar versión
              </Button>
              <DefaultModal
                title={modalTitle}
                body={modalBody}
                show={defaultModalShow}
                handleClose={handleCloseDefaultModal}
                handleConfirm={() => execFunction(actionModalConfirm)}
                titleBtnClose="Cancelar"
                titleBtnSave="Confirmar"
              />
              <SimpleCenteredModal
                closeButton
                title={modalTitle}
                body={modalBody}
                show={modalShow}
                onHide={() => setModalShow(false)}
                centered={false}
                size="sm"
              />
            </Col>
            <Col xs={1} style={{ paddingRight: 0 }}>
              <BasicDropdown
                small
                right
                noArrow
                variant="link"
                classColor="text-dark"
                titleDrop={<MoreVertical />}
                items={dropdownVersionItems}
              />
            </Col>
          </Row>
        </Col>
      )}
      {publicVersion && otState !== 'in_process' && (
        <Col md={5} className="ml-3">
          <Row className="text-uppercase d-flex align-items-center">
            <Col md={3}>
              <p className="mb-2">
                <span className="font-weight-bold d-inline-block w-50">Valor Neto:</span> {parsedTotalPrice}
              </p>
              <p className="mb-2">
                <span className="font-weight-bold d-inline-block w-50">IVA ({companyTaxRate}):</span> {parsedTotalTax}
              </p>
            </Col>
            <Col md={3} className="text-primary">
              <h4 className="font-weight-bold mb-2">Total: {parsedTotalPriceTax}</h4>
            </Col>
            <Col md={{ span: 3, offset: 3 }} className="font-weight-bold d-flex justify-content-end">
              Versión #{currentVersion.versionNumber}: {currentVersion.createdAt}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default ProjectNavbarInfo;
