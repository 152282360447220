import {Table} from 'react-bootstrap';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

const ProjectQuoteTable = ({publicVersion, project, handleAdvancedConfiguration}) => {
  const {
    companyTaxRate,
    parsedTotalDiscount,
    parsedTotalPriceWithDiscount,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    projectWorksAttributes
  } = project;
  const projectWorksBody = () =>
    projectWorksAttributes &&
    projectWorksAttributes.map((projectWork, indexWork) => {
      const projectDetails = projectWork.projectDetailsAttributes.filter(projectDetail => !projectDetail.hidden);
      return (
        <Fragment key={`project-work-${indexWork.toString()}`}>
          {projectDetails.map((projectDetail, indexDetail) =>
            indexDetail === 0 ? (
              <>
                <tr>
                  <td colSpan={publicVersion ? 7 : (project?.hideSubcategory ? 9 : 10)} className="font-weight-bold" style={{color: '#5e99b8'}}>
                    {projectWork.name}
                  </td>
                </tr>
                <tr key={`project-detail-${indexDetail.toString()}`}>
                  {projectDetailBody(projectDetail, indexWork, indexDetail)}
                </tr>
              </>
            ) : (
              <tr key={`project-detail-${indexDetail.toString()}`}>
                {projectDetailBody(projectDetail, indexWork, indexDetail)}
              </tr>
            )
          )}
          {publicVersion && projectDetails.length > 0 && (
            <tr>
              <th colSpan={publicVersion ? (project?.hideSubcategory ? '4' : '5') : '6'}/>
              <th>Subtotal</th>
              <th>{currencyFormat(projectDetails)}</th>
            </tr>
          )}
        </Fragment>
      );
    });

  const calculatedPrice = projectDetailsAttributes => {
    return projectDetailsAttributes.reduce((acc, current) => {
      const parsedPrice = parseFloat(current.price) || 0;
      return acc + parsedPrice;
    }, 0);
  };

  const currencyFormat = (projectDetailsAttributes, sym = '$') => {
    const price = calculatedPrice(projectDetailsAttributes);
    const priceWithFormat = price.toLocaleString().replace(',', '.');
    return `${sym}${priceWithFormat}`;
  };

  const isDiscounted = () => {
    if (parsedTotalDiscount) {
      const discount = parseInt(parsedTotalDiscount.substring(1), 10);
      if (discount) return true;
    }
    return false;
  };

  const projectDetailBody = (vProjectDetail, indexWork, indexDetail) => {
    const {
      categoryName,
      cost,
      parsedCost,
      parsedTotalCost,
      parsedMargin,
      parsedUnitPrice,
      parsedPrice,
      productCategoryName,
      productId,
      productNameShow,
      quantity,
      parsedPriceDiscount
    } = vProjectDetail;
    const inputName = `project[projectWorksAttributes][${indexWork}][projectDetailsAttributes][${indexDetail}]`;
    const nameToShow = productNameShow;
    return (
      <>
        <td>{categoryName}</td>
        {!project?.hideSubcategory && <td>{productCategoryName}</td>}
        {publicVersion ? (
          <td className={`text-uppercase ${nameToShow ? '' : 'font-italic text-muted'}`}>
            {nameToShow || 'Ítem sin Nombre'}
          </td>
        ) : (
          <td className={nameToShow ? '' : 'font-italic text-muted'}>
            <Link className="text-uppercase" to={`/products/${productId}`}>
              {nameToShow || 'Ítem sin Nombre'}
            </Link>
          </td>
        )}
        {!publicVersion && (
          <td>
            <div
              className="text-primary"
              role="button"
              onClick={() => handleAdvancedConfiguration(vProjectDetail, inputName, productId)}
            >
              {parsedCost}
            </div>
          </td>
        )}
        <td>{quantity || 0}</td>
        {!publicVersion && <td>{parsedTotalCost}</td>}
        <td>{parsedUnitPrice}</td>
        <td className="text-warning">{parsedPriceDiscount}</td>
        <td>{parsedPrice}</td>
        {!publicVersion && <td>{cost ? parsedMargin : 'No aplica'}</td>}
      </>
    );
  };

  return (
    <Table responsive borderless hover>
      <thead className="border-top-borderless-table">
      <tr className="header-border-bottom">
        <th>Categoría</th>
        {!project?.hideSubcategory && <th>Subcategoría</th>}
        <th>Ítem</th>
        {!publicVersion && <th>Costo Neto Unit</th>}
        <th>Cantidad</th>
        {!publicVersion && <th>Costo Neto Total</th>}
        <th>P. Venta Unit</th>
        <th>Descuento</th>
        <th>P. Venta Total</th>
        {!publicVersion && <th>Margen</th>}
      </tr>
      </thead>
      <tbody>
      {projectWorksBody()}
      {publicVersion && (
        <>
          <tr className="border-top-borderless-table">
            <th colSpan={publicVersion ? (project?.hideSubcategory ? '4' : '5') : '6'}/>
            <th>Valor Neto</th>
            <th>{parsedTotalPrice}</th>
          </tr>
          {isDiscounted() && (
            <>
              <tr className="font-weight-bold">
                <th colSpan={publicVersion ? (project?.hideSubcategory ? '4' : '5') : '6'}/>
                <th>Descuento</th>
                <th className="text-secondary">- {parsedTotalDiscount}</th>
              </tr>
              <tr className="font-weight-bold">
                <th colSpan={publicVersion ? (project?.hideSubcategory ? '4' : '5') : '6'}/>
                <th>Valor Total Neto</th>
                <th>{parsedTotalPriceWithDiscount}</th>
              </tr>
            </>
          )}
          <tr>
            <th colSpan={publicVersion ? (project?.hideSubcategory ? '4' : '5') : '6'}/>
            <th>IVA ({companyTaxRate})</th>
            <th>{parsedTotalTax}</th>
          </tr>
          <tr>
            <th colSpan={publicVersion ? (project?.hideSubcategory ? '4' : '5') : '6'}/>
            <th>
              <h2>Total</h2>
            </th>
            <th>
              <h2>{parsedTotalPriceTax}</h2>
            </th>
          </tr>
        </>
      )}
      </tbody>
    </Table>
  );
};
export default ProjectQuoteTable;
