import React, { useRef } from 'react';
import { Card, ProgressBar, Nav } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { parse, isValid } from 'date-fns';
import ReactTooltip from 'react-tooltip';

const Cards = ({ project, index, moveProject, column }) => {
  let projectDate = null;
  if (project?.formated_promise_date) {
    try {
      projectDate = parse(project.formated_promise_date, 'dd/MM/yyyy HH:mm', new Date());
      if (!isValid(projectDate)) {
        projectDate = new Date(project.formated_promise_date);
      }
    } catch (error) {
      projectDate = new Date(project.formated_promise_date);
    }
    if (!isValid(projectDate)) {
      projectDate = null;
    }
  }

  const currentDate = new Date();
  const isDateBeforeOrEqualToday = projectDate && projectDate.getTime() <= currentDate.getTime();

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'project',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current.getBoundingClientRect();
      const mousePosition = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }


      moveProject(dragIndex, hoverIndex, column.id, item.item.id);
      // eslint-disable-next-line no-param-reassign
      // item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { item: project, index },
    type: 'project',
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  const convertPercentageToNumber = percentage => {
    return percentage ? parseFloat(percentage.replace('%', '')) : 0;
  };

  const receptionProgress = convertPercentageToNumber(project.reception_progress_percentage);
  const inspectionProgress = convertPercentageToNumber(project.mechanic_progress_percentage);

  const isChecklistProgressCompleted = receptionProgress === 100 && inspectionProgress === 100;
  const isChecklistProgressIdle = receptionProgress === 0 && inspectionProgress === 0;
  const isCompletedWorks =
    project.project_works_count === 0 ||
    (project.count_completed_works && project.count_completed_works === project.project_works_count);

  const preInspectionAttributes = project.checklist_attributes.filter(att => att.checklist_type === 'pre_inspection');
  const preInspectionProgress = preInspectionAttributes.map(att => att.progress);
  const totalPreInspectionProgress = preInspectionProgress.reduce((sum, value) => sum + value, 0);
  const averagePreInspectionProgress = Math.round(totalPreInspectionProgress / preInspectionProgress.length) || 0;

  const style = {
    width: '180px',
    minWidth: '180px',
    borderRadius: '5px',
    backgroundColor: 'white',
    fontSize: '10px',
    opacity: isDragging ? 0 : 1,
    borderTopWidth: '4px',
    borderTopStyle: 'solid',
    cursor: 'grab',
    boxShadow: '3px 4px 4px rgba(0, 0, 0, 0.1)',
    borderTopColor:
      project.sectionCode === 'budget_sent'
        ? '#8e8e8e'
        : ['working'].includes(project.sectionCode) && isCompletedWorks && project.ot_state === 'budget_approved'
        ? '#5cc689'
        : ['working'].includes(project.sectionCode) && !isCompletedWorks && project.ot_state === 'budget_approved'
        ? '#8e8e8e'
        : ['working'].includes(project.sectionCode) && project.ot_state === 'delivered_warranty'
        ? '#8e8e8e'
        : ['working'].includes(project.sectionCode) && project.ot_state === 'rejected'
        ? '#8e8e8e'
        : ['working'].includes(project.sectionCode) && project.ot_state === 'partial_budget'
        ? '#5e99b8'
        : ['working'].includes(project.sectionCode) && project.ot_state === 'ot_completed'
        ? '#5cc689'
        : ['working'].includes(project.sectionCode) && project.ot_state === 'warranty'
        ? '#4dbfff'
        : isChecklistProgressCompleted
        ? '#5cc689'
        : isChecklistProgressIdle
        ? '#8e8e8e'
        : '#ffad26'
  };

  const tooltipId = `kanbanTooltip-${project.id}`;

  return (
    <>
      <Card ref={ref} style={style} className="my-2 mx-0 p-2" data-tip={!!project.notes} data-for={tooltipId}>
        <Card.Header
          className="d-flex border-0 p-0 "
          style={{ padding: '5px', backgroundColor: 'white', fontSize: '12px' }}
        >
          <Nav.Link
            as={Link}
            to={`/projects/${project.id}`}
            style={{
              padding: 0,
              color: 'black'
            }}
          >
            <span className="font-weight-bold p-0">OT #{project.id}</span>
          </Nav.Link>
        </Card.Header>
        <Card.Body className="p-0 m-0">
          <i className="bi bi-newspaper" style={{ color: '#cdcdcd' }} />
          <Nav.Link
            as={Link}
            to={`/projects?plate=${project?.vehicle?.plate}`}
            style={{
              padding: 0,
              display: 'inline-block'
            }}
          >
            <span className="mx-1">{project?.vehicle?.plate}</span>
          </Nav.Link>
          <br />
          <i className="bi bi-car-front-fill" style={{ color: '#cdcdcd' }} />
          <span className="mx-1">
            {project?.vehicle?.vehicle_description}
          </span>
          <br />
          <i className="bi bi-person-fill" style={{ color: '#cdcdcd' }} />
          <Nav.Link
            as={Link}
            to={`/projects?client_id=${project?.client?.id}`}
            style={{
              padding: 0,
              display: 'inline-block'
            }}
          >
            <span className="mx-1">{project?.client?.full_name}</span>
          </Nav.Link>
        </Card.Body>
        <Card.Body className="p-0 mt-1">
          <i className="bi bi-calendar-event" style={{ color: '#cdcdcd' }} />
          <span className="mx-1" style={{ color: isDateBeforeOrEqualToday ? 'red' : 'black' }}>
            {project?.formated_promise_date}
          </span>
          <br />
          {project?.ot_state === 'in_process' ? (
            <div>
              <Card.Body className="d-flex flex-column  p-0 m-0  ">
                {preInspectionAttributes.length > 0 && (
                  <>
                    <span>Pre-Inspección</span>
                    <div className="d-flex p-0 m-0">
                      <ProgressBar
                        now={averagePreInspectionProgress}
                        variant={averagePreInspectionProgress === 100 ? 'success' : 'warning'}
                        style={{ width: '140px', height: '8px' }}
                      />
                      <span className="p-0 ml-1 my-0">{`${averagePreInspectionProgress}%`}</span>
                    </div>
                  </>
                )}
                <span>Recepción</span>
                <div className="d-flex p-0 m-0">
                  <ProgressBar
                    now={receptionProgress}
                    variant={receptionProgress === 100 ? 'success' : 'warning'}
                    style={{ width: '140px', height: '8px' }}
                  />
                  <span className="p-0 ml-1 my-0">{`${receptionProgress}%`}</span>
                </div>
                <span>Inspección Técnica</span>
                <div className="d-flex p-0 m-0">
                  <ProgressBar
                    now={inspectionProgress}
                    variant={inspectionProgress === 100 ? 'success' : 'warning'}
                    style={{ width: '140px', height: '8px' }}
                  />
                  <span className="p-0 ml-1 my-0">{`${inspectionProgress}%`}</span>
                </div>
              </Card.Body>
            </div>
          ) : (
            <Card.Body className="d-flex p-0 m-0 justify-content-between ">
              <div className="m-0 p-0 font-weight-bold " style={{ fontSize: '11px' }}>
                <i className="bi bi-check2-square" style={{ color: '#202020' }} />
                <span className="p-0 ml-1 my-0">{`${project?.count_completed_works ||
                  0}/${project?.project_works_count || 0}`}</span>
              </div>
              <div className="m-0 p-0 d-flex">
                {project.has_comments ? (
                  <i className="bi bi-wechat" style={{ fontSize: '1.1rem', color: '#2F3E75' }} />
                ) : null}
                {project.has_incidents ? (
                  <i
                    className="bi bi-car-front-fill"
                    style={{ fontSize: '1.1rem', color: '#EB7878', marginLeft: '5px' }}
                  />
                ) : null}
              </div>
            </Card.Body>
          )}
        </Card.Body>
      </Card>
      {project.notes && (
        <ReactTooltip place="right" id={tooltipId} type="dark" effect="solid" delayShow={1000} delayHide={500}>
          {project.notes}
        </ReactTooltip>
      )}
    </>
  );
};

export default withRouter(Cards);
