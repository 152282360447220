import React from 'react';
import { Button, Table } from 'react-bootstrap';

const ProjectAutopartsCheckout = ({
                            productsToBuy,
                            productsPrice,
                            shipmentPrice,
                            buyRequest
                          }) => {
  return (
    <>
      <Table responsive size="sm" className="mt-2">
        <tbody>
          {productsToBuy.map(product => {
            return (<tr>
              <td className='float-right border-0'>{product.quantity}x</td>
              <td className='border-0'>{product.name}</td>
              <td className='border-0'>{product.code}</td>
              <td className='float-right border-0'>${product.price}</td>
            </tr>);
          })}
          <tr className='border-top' style={{ fontWeight: 'bold' }}>
            <td></td>
            <td className='pb-3'>SUBTOTAL</td>
            <td></td>
            <td className='float-right'>${productsPrice}</td>
          </tr>
          <tr>
            <td className='border-0'></td>
            <td className='border-0'>Despacho</td>
            <td className='border-0'>24/48 horas (Periodo de prueba)</td>
            <td className='float-right border-0'>${shipmentPrice}</td>
          </tr>
          <tr className='border-top' style={{ fontWeight: 'bold' }}>
            <td></td>
            <td>TOTAL</td>
            <td></td>
            <td className='float-right'>${productsPrice+shipmentPrice}</td>
          </tr>
        </tbody>
      </Table>
      <Button className="px-5 float-right" variant="primary" onClick={buyRequest}>
        Pagar
      </Button>
    </>
  );
};

export default ProjectAutopartsCheckout;
