import React, { useEffect, useState } from "react";
import { Row, Col, Button, Table, Spinner } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { withFormik, Form, Field, FieldArray, getIn, useFormikContext } from 'formik';
import { FormikInput, FormikCheckBox } from '../../components';
// import { Image as FeatherImage } from 'react-feather';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import { automarcoProductsList } from '../../requests/autoparts';

const ProjectAutopartsListQuote = ({ project }) => {
  const {errors, setFieldValue, touched, values} = useFormikContext();
  const { projectAutopartsAttributes } = project;
  const modelKey = 'quotation';
  const [vehicleMatch, setVehicleMatch] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();

  const addProduct = (products, category) => {
    projectAutopartsAttributes.find(ap => ap.categoryId === category).quotationAutoparts.push(...products);
    setFieldValue(modelKey, projectAutopartsAttributes);
    setOnRequest(false);
  };

  const noProductsFound = (category) => {
    projectAutopartsAttributes.find(ap => ap.categoryId === category).quotationNotFound = true;
    setFieldValue(modelKey, projectAutopartsAttributes);
    setOnRequest(false);
  };

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
  };

  // const handleImgClick = (image) => {    
  //   setModalBody(<img src={image} alt=''/>);
  //   setModalShow(true);
  // };

  const quote = () => {
    setOnRequest(true);
    const categoryIds = projectAutopartsAttributes.map(c => c.categoryId);
  
    automarcoProductsList({
      dispatch,
      categories: categoryIds,
      plate: project.vehiclePlate, 
      failureCallback: response => console.log(response),
      successCallback: addProduct, 
      matchCallback: () => setVehicleMatch('true'), 
      noMatchCallback: () => {
        setVehicleMatch('false');
        setOnRequest(false);
      }, 
      noProductsCallback: noProductsFound
    });
  };

  useEffect(quote, [projectAutopartsAttributes]);

	return (
    <Form>
      {onRequest && (
        <div className="containerSpinnerLoad">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {vehicleMatch === 'false' && <span>No encontramos repuestos para este vehículo.</span>}
      {vehicleMatch === 'true' && 
        <>
          <FieldArray name={modelKey}>
            {({ push }) => (
              <>
                {projectAutopartsAttributes.map((autopart, index) => {
                  if (autopart._destroy) {
                    return undefined;
                  }
                  return (<Row key={`project-quotation-autopart-input-${index.toString()}`}>
                    <Col md={12} className='border-bottom p-2' style={{ cursor: 'pointer', backgroundColor: '#f7f7f7', fontWeight: 'bold' }} onClick={() => {
                      autopart.isClosed = !autopart.isClosed;
                      setFieldValue(modelKey, getIn(values, modelKey));
                    }}>
                      {autopart.name}
                    </Col>
                    {!autopart.isClosed ? (
                      <>
                      {autopart.quotationAutoparts.length ?
                      (<Table responsive size="sm" className="mt-2 mb-0">
                        <thead>
                          <tr>
                            <th>Comprar/Cant.</th>
                            <th>Producto</th>
                            <th>Código</th>
                            <th>Unidades por caja</th>
                            <th>Precio</th>
                          </tr>
                        </thead>
                        <tbody>
                          {autopart.quotationAutoparts.map((ap, i) => {
                            ap.quantity = autopart.quantity;
                            return (<tr key={`project-quotation-autopart-input-${index.toString()}-${i.toString()}`}>
                              <td className="d-flex">
                                <div className="mr-2">
                                <Field name={`${modelKey}[${index}][quotationAutoparts][${i}][buy]`}>
                                  {({field}) => (
                                    <FormikCheckBox {...field} type="switch" field={field} custom/>
                                  )}
                                </Field>
                                </div>
                                <div className="ml-2" style={{ maxWidth: '50px' }}>
                                <Field name={`${modelKey}[${index}][quotationAutoparts][${i}][quantity]`}>
                                  {({field}) => (
                                    <FormikInput
                                      {...field}
                                      inputType="number"
                                      defaultValue={ap.quantity}
                                      disabled={!getIn(values,`${modelKey}[${index}][quotationAutoparts][${i}][buy]`)}
                                      margin='m-0'
                                      error={getIn(errors, field.name)}
                                      touched={getIn(touched, field.name)}
                                    />
                                  )}
                                </Field>
                                </div>
                                {/* {ap.img ? 
                                (<span style={{ color: '#00517B', cursor: 'pointer' }} className="font-weight-bold" onClick={() => handleImgClick(ap.img)}>
                                  <FeatherImage className="icon-file-text" strokeWidth="1.5" size={16} />
                                </span>) : undefined} */}
                              </td>
                              <td>
                                {ap.name}
                                {/* {imageIcon(ap.imagesInfo, ap.name)} */}
                              </td>
                              <td>
                                {ap.code}
                              </td>
                              <td>
                                {ap.units}
                              </td>
                              <td>
                                ${ap.price}
                              </td>
                            </tr>);
                          })}
                        </tbody>
                      </Table>) : (<>{autopart.quotationNotFound && <span className="pl-2" style={{ color: 'red' }}>No se encontraron productos.</span>}</>)
                      }                      
                      </>
                    ) : undefined}
                  </Row>);
                })}
              </>
            )}
          </FieldArray>
          <Button className="px-5 float-right" type="submit">
            Pagar
          </Button>
        </>
      }
      <SimpleCenteredModal
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </Form>
	);
};

const setInitialValues = ({ project }) => {
  project.projectAutopartsAttributes.map(ap => ap.quotationAutoparts = []);
  return { project };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false
})(ProjectAutopartsListQuote);
