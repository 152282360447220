import * as Yup from 'yup';

import { emailRegex } from './utils';
import { getCountryFactory } from "../utils/utils";

Yup.addMethod(Yup.string, 'email', function emailRutValidate(message) {
  return this.test('test-name', message, function validLogin(value) {
    const { path, createError } = this;
    if (!value) {
      return false;
    }
    if (emailRegex.test(value)) {
      return true;
    }
    if (value.length >= 4) {
      return createError({
        path,
        message: 'El email que ingresaste no es válido'
      });
    }
    return false;
  });
});

Yup.addMethod(Yup.string, 'rut', function rutValidate(message, country) {
  return this.test('test-name', message, function validRut(value) {
    const countryFactory = getCountryFactory(country)
    if (value === null || value === undefined) {
      return true;
    }
    if (countryFactory.isValidRut(value)) {
      return true;
    }
    return false;
  });
});

Yup.addMethod(Yup.string, 'phone', function phoneValidate(message, country) {
  return this.test('test-name', message, function validPhone(value) {
    const countryFactory = getCountryFactory(country)
    return (value && countryFactory.validPhoneInput(value)) || value === undefined
  });
});
