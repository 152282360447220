import React from 'react';
import { withFormik, Form, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import CompanyForm from './ProfileForm/CompanyForm';
import UserForm from './ProfileForm/UserForm';

const ProfileForm = props => {
  const { onHide, submitVariant, errors, touched, action, setFieldValue, fromShow, fromEdit, user, company } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  return (
    <Form>
      <h4 className="text-uppercase">Información de Usuario</h4>
      <Row className="mt-3 mb-4">
        <UserForm
          action={action}
          user={user}
          errors={errors}
          setFieldValue={setFieldValue}
          touched={touched}
          fromShow={fromShow}
        />
      </Row>
      {!fromEdit && (
        <>
          <h4 className="text-uppercase">Información de Contacto Empresa</h4>
          <Row className="mt-3 mb-3">
            <CompanyForm
              action={action}
              company={company}
              errors={errors}
              setFieldValue={setFieldValue}
              touched={touched}
              fromShow={fromShow}
            />
          </Row>
        </>
      )}

      {!fromShow && (
        <Row className="d-flex justify-content-end mb-4">
          <Col md={2}>
            <Button type="submit" variant={submitVariant} block onClick={onHide}>
              {btnMessage}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

const setInitialValues = ({ user, company }) => {
  const { id, avatarInfo, email, name, lastName, whatsappPhoneNumber } = user;

  return {
    user: {
      id,
      avatar: getIn(avatarInfo, 'fileSignature'),
      email,
      name,
      lastName,
      whatsappPhoneNumber
    },
    company
  };
};

const validationSchema = ({ user }) => {
  return Yup.object().shape({
    user: Yup.object().shape({
      name: Yup.string().nullable(),
      lastName: Yup.string().nullable(),
      whatsappPhoneNumber: Yup.string()
          .nullable()
          .phone('El formato no es válido', user.companyCountry)
    })
  });
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileForm);
