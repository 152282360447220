import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { Col, Button, Alert } from 'react-bootstrap';
import ProjectShowForm from './Form/ProjectShowForm';
import { DefaultModal, RichEditor } from '../../components';
import { getOldVersionPdfRequest, sendEmailRequest } from '../../requests/projects';
import { showCompanyRequest } from '../../requests/companies';
import downloadFile from '../../services/utils';
import { sendAlert } from '../../actions/utils';

const ProjectExports = ({ id, isDeskSale, version, versionNum, setMoreData, project, handleUpdateRequest }) => {
  const [modalShow, setModalShow] = useState(false);
  const [progressOtStateEmailBody, setProgressOtStateEmailBody] = useState('');
  const [companyInfo, setCompanyInfo] = useState({});

  const dispatch = useDispatch();

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessSendReception = () => {
    dispatch(sendAlert({ kind: 'success', message: 'PDF Presupuesto ha sido enviado exitosamente' }));
    setMoreData(m => !m);
  };

  const downloadOldVersionQuote = (format, type) => {
    getOldVersionPdfRequest({
      id,
      type,
      versionNum,
      dispatch,
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const sendEmail = type => {
    const sendParams = {
      email: progressOtStateEmailBody
    };
    sendEmailRequest({
      id,
      type,
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: () => handleSuccessSendReception(type),
      failureCallback: handleFailureRequest
    });
  };
  const modalBody = (
    <>
      <Col md={12} className="mb-2">
        <Alert variant="secondary">
          <p className="font-weight-bold">
            A partir de esta acción, se generará una nueva versión de la Orden de Trabajo (OT).
          </p>

          <p className="mt-3 font-italic ">
            Un PDF será generado y enviado al cliente a partir de esta versión, además de un link público para revisar
            el estado general de la OT
          </p>
        </Alert>
      </Col>
      <Col md={11} className="mx-auto">
        <ProjectShowForm
          from="NavbarInfo"
          isDeskSale={isDeskSale}
          project={project}
          action="edit"
          submitVariant="success"
          formRequest={handleUpdateRequest}
          compact
        />
      </Col>
      <Col md={12} className="mt-4">
        <Alert variant="warning">
          <p className="font-weight-bold text-center">Debe guardar cambios en OT antes de Confirmar</p>
          <p className="mt-3 font-italic ">
            De lo contrario, cambios no guardados NO se reflejarán en Presupuesto ni en la OT
          </p>
        </Alert>
      </Col>
      <Col md={12}>
        <RichEditor
          label="Cuerpo email"
          data={progressOtStateEmailBody}
          setFieldValue={() => {}}
          setData={setProgressOtStateEmailBody}
        />
      </Col>
    </>
  );

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleConfirm = () => {
    sendEmail('quote');
    setModalShow(false);
  };

  const handleShowCompanySuccess = response => {
    setCompanyInfo(camelCaseRecursive(response.data));
  };

  const handleGetCompanyData = () => {
    showCompanyRequest({
      dispatch,
      successCallback: handleShowCompanySuccess,
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateEmailBodyContent = () => {
    const { progressOtStateEmailBody: emailBody } = companyInfo;
    const { formatedPromiseDate } = project;
    const promiseDateText = `<p>La fecha y hora prevista para la entrega de su vehículo es ${formatedPromiseDate}.
    Para más información acerca del estado de su vehiculo, puede revisar el estado de los trabajo a continuación</p>`;
    const body = emailBody && promiseDateText ? emailBody.concat(promiseDateText) : '';
    setProgressOtStateEmailBody(body);
  };

  useEffect(handleGetCompanyData, []);
  useEffect(handleUpdateEmailBodyContent, [companyInfo, project]);

  return (
    <>
      {version && (
        <Col md="10" className="mb-2">
          <Button variant="light-darker" block onClick={() => downloadOldVersionQuote('pdf', 'quote')}>
            PDF Presupuesto (Versión #{versionNum})
          </Button>
        </Col>
      )}
      {!version && (
        <Col className="mb-2 mb-lg-0">
          <Button variant="primary" block onClick={() => setModalShow(true)}>
            Enviar Presupuesto
          </Button>
        </Col>
      )}
      <DefaultModal
        title="Cambiar Enviar Presupuesto"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ProjectExports;
