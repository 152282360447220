import React from 'react';
import { Row, Col } from 'react-bootstrap';
import useCountryFactory from "../../hooks/useCountryFactory";

const ProjectClientDetails = ({ client }) => {
  const { nationalIdentification, fullName, fullAddress, email, cellphone, phone } = client;
  const countryFactory = useCountryFactory();
  return (
    <Row className="pt-2">
      <Col md="11">
        <Row>
          <Col md="6">
            <p>
              <span className="font-weight-bold data-title">{countryFactory.getLabelForNationalIdentification()}:</span> {nationalIdentification}
            </p>
            <p>
              <span className="font-weight-bold data-title">NOMBRE:</span> {fullName}
            </p>
            <p>
              <span className="font-weight-bold data-title">DIRECCIÓN:</span> {fullAddress}
            </p>
          </Col>
          <Col md="6">
            <p>
              <span className="font-weight-bold data-title">EMAIL:</span> {email}
            </p>
            <p>
              <span className="font-weight-bold data-title">CELULAR:</span> {cellphone}
            </p>
            <p>
              <span className="font-weight-bold data-title">TELÉFONO:</span> {phone}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectClientDetails;
