import React from 'react';
import { Row, Col } from 'react-bootstrap';

import ProjectDataTable from '../ProjectDataTable';
import columns from '../Columns';
import { parseQuery } from '../../../services/utils';
import { ProjectQuoteIndex } from "../../index";

const ProjectIndex = ({ location }) => {
  const { search: urlSearch } = location;
  const urlQueries = parseQuery(urlSearch);
  return (
    <Row>
      <Col md={12} className="mx-2">
        <Row className="mt-4">
          <Col>
            <h2 className="text-uppercase">Presupuestos</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ProjectDataTable urlQueries={urlQueries} columns={columns} isQuote filterId="ProjectQuoteIndex" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectIndex;
