import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';

const ProjectWorkShow = ({ project }) => {
  const { user, projectWorksAttributes } = project;

  return (
    <Row>
      <Col md={12} className="mb-3">
        <strong>Trabajos</strong>
      </Col>
      <Col md={12}>
        <Row>
          <Col md={3} className="project-works-info-box project-info-division text-uppercase small">
            <div className="info mb-3">
              <span>Mecánico Responsable</span>
              <p>{user?.fullName}</p>
            </div>
            <div className="info">
              <span>Contacto de Mecánico</span>
              <p>{user?.email}</p>
            </div>
          </Col>
          <Col>
            <Table responsive hover size="sm">
              <thead>
                <tr>
                  <th>Trabajo</th>
                  <th>Responsable</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {projectWorksAttributes.map((projectWork, index) => (
                  <tr key={`pw-table-${index.toString()}`}>
                    <td>{projectWork.name}</td>
                    <td>{projectWork.doneByName}</td>
                    <td>{projectWork.done ? 'Realizado' : 'Pendiente'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectWorkShow;
