import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { FormikCheckBox, FormikInput, NestedAttributes } from '../../components';

const SubCategoryInput = ({ index, errors, touched, paint }) => (
  <Col>
    <Row>
      <Col md={paint ? 6 : 8}>
        <Field name={`productCategories[subCategoriesAttributes][${index}][name]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              label="Nombre de subcategoría"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      { paint && 
        <Col md={2}>
          <Field name={`productCategories[subCategoriesAttributes][${index}][paintPrice]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                defaultValue={0}
                label="Precio pieza"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      }
      <Col md={2}>
        <Field name={`productCategories[subCategoriesAttributes][${index}][margin]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="number"
              defaultValue={0}
              value={field.value || 0}
              label="Margen"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={2} className="my-auto inverse">
        <Field name={`productCategories[subCategoriesAttributes][${index}][inverse]`}>
          {({ field }) => <FormikCheckBox {...field} field={field} label="Inverso" custom />}
        </Field>
      </Col>
    </Row>
  </Col>
);

const ProductCategoryForm = props => {
  const { action, errors, setFieldValue, handleModalClose, onHide, submitVariant, touched, values } = props;
  const { subCategoriesAttributes, name } = values.productCategories;

  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';

  const addSubCategories = () => {
    const mapResults = subCategoriesAttributes.map((body, index) => {
      if (body._destroy) {
        return undefined;
      }

      return (
        <SubCategoryInput key={`subcategory-${index.toString()}`} index={index} errors={errors} touched={touched} paint={name === 'PINTURA'} />
      );
    });

    return (
      <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <NestedAttributes
          removeFirstItem
          mapInputs={mapResults}
          arrayValues={subCategoriesAttributes}
          setFieldValue={setFieldValue}
          valuePath="productCategories[subCategoriesAttributes]"
          newAttributes={{ name: '' }}
          btnMessage="Agrega nueva subcategoría"
        />
      </div>
    );
  };

  return (
    <Form>
      <Row className="mt-3 mb-4">
        <Col md={4}>
          <Field name="productCategories[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre de la Categoría"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <h4 className="text-uppercase mt-3 mb-4">Subcategorías</h4>
          {addSubCategories()}
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-4">
        {handleModalClose && (
          <Col md={{ span: 3, offset: 6 }}>
            <Button className="btn" variant="primary" block onClick={handleModalClose}>
              Cancelar
            </Button>
          </Col>
        )}
        <Col md={3}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { productCategories } = props;
  return { productCategories };
};

const validationSchema = Yup.object().shape({
  productCategories: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    subCategoriesAttributes: Yup.array().of(
      Yup.object().shape({
        margin: Yup.number().min(0, 'El porcentaje debe ser mayor o igual que 0')
      })
    )
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProductCategoryForm);
