import ArCountryFactory from "./ArCountryFactory";
import ClCountryFactory from "./ClCountryFactory";

export const uuid = () => Math.floor(Math.random() * Date.now()).toString();

export function debounce(func, delay) {
  let debounceTimer;
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export const getCountryFactory = (country) => {
  if (country === 'ar') {
    return new ArCountryFactory()
  }
  return new ClCountryFactory()
}
