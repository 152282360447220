import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { withFormik, Form, Field, getIn } from 'formik';
import { BasicTextArea, FormikNumberFormat, FormikInput } from '../../components';

const PlanUserForm = ({ errors, onHide, setFieldValue, touched, planName }) => {
  return (
    <>
      <p className="lead text-center w-75 mx-auto pt-2 pb-5">
        Si estás interesado en el <span className="font-weight-bold font-italic">{planName}</span>, deja tus detalles a
        continuación y te contactaremos a la brevedad!
      </p>
      <Form className="mx-3">
        <Row className="mt-3">
          <Col md={12}>
            <Field name="contact[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="contact[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="contact[phone]">
              {({ field }) => (
                <FormikNumberFormat
                  {...field}
                  abbr
                  label="Tel. Celular"
                  format="+56# ########"
                  onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Field name="contact[comments]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Comentarios"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end my-4">
          <Col md={4}>
            <Button type="submit" variant="primary" block onClick={onHide}>
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { contact, planName } = props;
  return { contact, planName };
};

const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    name: Yup.string().required('Ingresa tu nombre'),
    phone: Yup.string()
      .required('Debes ingresar un número de celular')
      .min(13, 'Debe tener al menos 9 caracteres')
      .phone('El formato no es válido'),
    email: Yup.string()
      .required('Debes ingresar un email')
      .email('Debes ingresar un email válido'),
    comments: Yup.string().nullable()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PlanUserForm);
