import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProjectDetailsRequest = ({ dispatch, format, params = {}, successCallback, failureCallback }) => {
  let formatt = '';
  if (format) formatt = `.${format}`;
  ApiService.request('get', `/project_details${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const updateProjectDetailRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/project_details/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexProjectDetailsRequest = AwesomeDebouncePromise(indexProjectDetailsRequest, 300);
